import Card from "./components/Card";
import Heading from "./components/Heading";
import ValidationErrors from "./components/ValidationErrors";
import FileUploader from "./components/FileUploader";
import { Carousel, CarouselItem } from 'element-ui';


import Vue from "vue";

Vue.component("card", Card);
Vue.component("heading", Heading);
Vue.component("validation-errors", ValidationErrors);
Vue.component("file-uploader", FileUploader);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);