<template>
    <div v-if="item" class="fixed-width mt-4">
        <div class="text-2xl pb-4">未通过审核列表：</div>

        <div class="text-center" v-if="item.images.filter(image => image.status == 'REJECT').length == 0">
            暂无数据
        </div>
        <div class="flex flex-wrap">
            <div class="w-full flex items-center justify-between mb-4 border-b pb-4" v-for="(image, index) in item.images" v-show="image.status == 'REJECT'">
                <div>
                    <span class="font-bold text-sm">{{ image.name }}</span>
                    <span v-if="image.reject_reason" class="block text-red-500 font-bold">{{ image.reject_reason }}</span>
                    <a :href="`${item.image_base_path}${image.file}`" target="_blank">
                        <img class="h-48" :src="`${item.image_base_path}${image.file}`">
                    </a>
                </div>
                <div>
                   <input @change="uploadImage($event, index)" class="hidden" type="file" :id="`upload-${index}`" name="file" />
                    <label class="btn cursor-pointer" :for="`upload-${index}`">
                        重新上传
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        item: null,
    }),

    mounted() {
        axios.get(`/member/special-decorations/${this.$route.query.id}`).then(response => this.item = response.data)
    },

    methods: {
        uploadImage(e, index) {
            const files = e.target.files
            if (files.length == 0) return

            let formData = new FormData();
            formData.append('file', files[0])
            axios.post('upload', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    let old = this.item.images[index]
                    old.file = response.data.path
                    old.status = null
                    this.item.images[index] = old

                    axios.post(`/member/special-decorations/${this.$route.query.id}/update`, { images: this.item.images})
                })
        },
    }
}
</script>
