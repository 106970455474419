import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Toasted from "vue-toasted";
import VModal from "vue-js-modal";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Http from "./Http";
import axios from 'axios';

window.axios = axios;
Vue.config.productionTip = false
Vue.use(VModal);
Vue.use(Toasted, {
  position: "bottom-right",
  duration: 5000,
  theme: "bubble"
});
Vue.use(Loading);

require("./components");

window.axios = new Http().create();

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
